import React from "react"
import { WithChildren } from "../../types/reactTypes"

type AlertType = "primary" | "info" | "success" | "warning" | "danger"
interface AlertsProps extends WithChildren {
  type: AlertType
}

function Alert(props: AlertsProps) {
  return <div className={`notification is-${props.type}`}>{props.children}</div>
}

export default Alert
